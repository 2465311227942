@use "sass:math";

:root {
  --blue-btn-color: #007bff;
  --box-shadow-common: 3px -1px 6px 3px rgba(0, 0, 0, 0.2);
  --backdrop-z-index: 1000;
  --registration-z-index: 2000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  font-family: "Hubballi", cursive;
  font-family: "Roboto", sans-serif;
}

.backdrop {
  display: none;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: var(--backdrop-z-index);
}

header {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1.2px solid rgba(0, 0, 0, 0.5);
}

.logo {
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: 50px;
  gap: 1rem;

  h1 {
    margin: 0;
    padding: 0;
  }

  :first-child {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 100%;
  }

  :nth-child(2) {
    grid-column: 2 / -1;
    grid-row: 1 / 2;
  }

  > * {
    align-self: center;
  }
}

main{
    margin-bottom: 4rem;
}

main nav {
  display: flex;
  justify-content: flex-end;
}

main nav ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin-right: 1rem;
}

main nav ul > * {
  margin-left: 0.7rem;
  padding: 1rem;
  text-align: center;
}

footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1.5rem;
  margin-top: 0.5rem;
  background: black;
  color: white;
  text-align: center;
}

.features {
  display: grid;
  place-content: center;
  grid-auto-rows: 500px;
}

.features p {
  font-size: 1.3rem;
}

$children: 1, 3, 5, 7, 9, 11, 13, 15, 17;

@each $i in $children {
  .features :nth-child(#{$i}),
  .features :nth-child(#{$i + 1}) {
    grid-column: 1 / -1;
    
    justify-self: center;
  }

  .features :nth-child(#{$i}),
  .features :nth-child(#{$i + 1}) {
    grid-row: #{math.div(($i + 1), 2)} / span 1;
  }

  .features :nth-child(#{$i + 1}) {
    margin-top: 3rem;
  }
}

.features .replaced-element-container img {
  height: 90%;
  width: 100%;
}

.hero-section {
  display: grid;
  place-content: center;
  grid-template-columns: 300px 700px;
  gap: 2rem;
  margin-top: 1rem;
}

.hero-text {
  font-size: 1.2rem;
  line-height: 1.4;
}

.content {
  --wall-color: #4663ac;
  --wall-width: 15px;
  position: relative;
  display: grid;
  grid-template-areas:
    ". wall icon youtube-logo youtube-logo youtube-logo . ."
    ". wall chrome-icon brave-icon edge-icon opera-icon . .";
  grid-template-columns: repeat(8, min-content);
  grid-template-rows: max-content 1fr;
  row-gap: 0.3rem;
  column-gap: 1rem;
  place-content: center;
  border-top: var(--wall-width) solid var(--wall-color);
}

.main-header {
  grid-row: 1 / 2;
  grid-column: 2 / -1;
}

.icon {
  grid-area: icon;
  align-self: center;
}

.header-row {
  grid-area: youtube-logo;
}

.wall {
  width: var(--wall-width);
  background: var(--wall-color);
  grid-row: 1 / -1;
  grid-column: 2 / 3;
}
.chrome-icon {
  grid-area: chrome-icon;
}

.brave-icon {
  grid-area: brave-icon;
}

.edge-icon {
  grid-area: edge-icon;
}

.opera-icon {
  grid-area: opera-icon;
}

.main-header {
  position: relative;
}

.main-header img {
  position: absolute;
  left: 0;
}
