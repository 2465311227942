:root {
  --blue-btn-color: #007bff;
  --box-shadow-common: 3px -1px 6px 3px rgba(0, 0, 0, 0.2);
  --backdrop-z-index: 1000;
  --registration-z-index: 2000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  font-family: "Hubballi", cursive;
  font-family: "Roboto", sans-serif;
}

.backdrop {
  display: none;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: var(--backdrop-z-index);
}

header {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1.2px solid rgba(0, 0, 0, 0.5);
}

.logo {
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: 50px;
  gap: 1rem;
}
.logo h1 {
  margin: 0;
  padding: 0;
}
.logo :first-child {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 100%;
}
.logo :nth-child(2) {
  grid-column: 2/-1;
  grid-row: 1/2;
}
.logo > * {
  align-self: center;
}

main {
  margin-bottom: 4rem;
}

main nav {
  display: flex;
  justify-content: flex-end;
}

main nav ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin-right: 1rem;
}

main nav ul > * {
  margin-left: 0.7rem;
  padding: 1rem;
  text-align: center;
}

footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1.5rem;
  margin-top: 0.5rem;
  background: black;
  color: white;
  text-align: center;
}

.features {
  display: grid;
  place-content: center;
  grid-auto-rows: 500px;
}

.features p {
  font-size: 1.3rem;
}

.features :nth-child(1),
.features :nth-child(2) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(1),
.features :nth-child(2) {
  grid-row: 1/span 1;
}

.features :nth-child(2) {
  margin-top: 3rem;
}

.features :nth-child(3),
.features :nth-child(4) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(3),
.features :nth-child(4) {
  grid-row: 2/span 1;
}

.features :nth-child(4) {
  margin-top: 3rem;
}

.features :nth-child(5),
.features :nth-child(6) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(5),
.features :nth-child(6) {
  grid-row: 3/span 1;
}

.features :nth-child(6) {
  margin-top: 3rem;
}

.features :nth-child(7),
.features :nth-child(8) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(7),
.features :nth-child(8) {
  grid-row: 4/span 1;
}

.features :nth-child(8) {
  margin-top: 3rem;
}

.features :nth-child(9),
.features :nth-child(10) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(9),
.features :nth-child(10) {
  grid-row: 5/span 1;
}

.features :nth-child(10) {
  margin-top: 3rem;
}

.features :nth-child(11),
.features :nth-child(12) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(11),
.features :nth-child(12) {
  grid-row: 6/span 1;
}

.features :nth-child(12) {
  margin-top: 3rem;
}

.features :nth-child(13),
.features :nth-child(14) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(13),
.features :nth-child(14) {
  grid-row: 7/span 1;
}

.features :nth-child(14) {
  margin-top: 3rem;
}

.features :nth-child(15),
.features :nth-child(16) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(15),
.features :nth-child(16) {
  grid-row: 8/span 1;
}

.features :nth-child(16) {
  margin-top: 3rem;
}

.features :nth-child(17),
.features :nth-child(18) {
  grid-column: 1/-1;
  justify-self: center;
}

.features :nth-child(17),
.features :nth-child(18) {
  grid-row: 9/span 1;
}

.features :nth-child(18) {
  margin-top: 3rem;
}

.features .replaced-element-container img {
  height: 90%;
  width: 100%;
}

.hero-section {
  display: grid;
  place-content: center;
  grid-template-columns: 300px 700px;
  gap: 2rem;
  margin-top: 1rem;
}

.hero-text {
  font-size: 1.2rem;
  line-height: 1.4;
}

.content {
  --wall-color: #4663ac;
  --wall-width: 15px;
  position: relative;
  display: grid;
  grid-template-areas: ". wall icon youtube-logo youtube-logo youtube-logo . ." ". wall chrome-icon brave-icon edge-icon opera-icon . .";
  grid-template-columns: repeat(8, min-content);
  grid-template-rows: max-content 1fr;
  row-gap: 0.3rem;
  column-gap: 1rem;
  place-content: center;
  border-top: var(--wall-width) solid var(--wall-color);
}

.main-header {
  grid-row: 1/2;
  grid-column: 2/-1;
}

.icon {
  grid-area: icon;
  align-self: center;
}

.header-row {
  grid-area: youtube-logo;
}

.wall {
  width: var(--wall-width);
  background: var(--wall-color);
  grid-row: 1/-1;
  grid-column: 2/3;
}

.chrome-icon {
  grid-area: chrome-icon;
}

.brave-icon {
  grid-area: brave-icon;
}

.edge-icon {
  grid-area: edge-icon;
}

.opera-icon {
  grid-area: opera-icon;
}

.main-header {
  position: relative;
}

.main-header img {
  position: absolute;
  left: 0;
}

.registeration-form {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: var(--box-shadow-common);
  background: white;
  z-index: var(--registration-z-index);
  min-height: 30rem;
}
.registeration-form h1 {
  margin-top: 0;
  text-align: center;
  text-decoration: underline;
}

.form-control {
  display: grid;
  grid-template-columns: 100px 1fr;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.form-control > * {
  align-self: center;
}

.form-control input {
  font-family: inherit;
  font-size: inherit;
  padding: 0.6rem;
}

.plan-explainer {
  position: relative;
  border: 2px solid black;
  padding: 0.5rem;
  min-height: 24rem;
  border-radius: 10px;
  box-shadow: var(--box-shadow-common);
}

.plan-explainer-features {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 0.5rem;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.plan-explainer-features > * {
  align-self: center;
}

.plan-explainer-price {
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem;
}

.plans-pricing {
  text-align: center;
}

.plan-explainer-text {
  padding-bottom: 0.3rem;
}

.plan-explainer-buy-btn,
.registration-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--blue-btn-color);
  color: white;
  font-family: inherit;
  font-size: 1rem;
  padding: 0.4rem;
  border-radius: 5px;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
}

.pricing-centered {
  min-height: 80vh;
}

.plan-grid {
  display: flex;
  gap: 1rem;
}

.form-notification {
  --color: red;
  display: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--color);
}

.form-notification-valid {
  --color: green;
}

.account-creation-success p,
.account-creation-failure p {
  font-size: 1.2rem;
}

.form-control-recovery {
  margin: 0 auto;
  width: 50%;
  margin-top: 3rem;
  display: grid;
  gap: 0.5rem;
}

.form-control-recovery * {
  display: inline-block;
  font-family: inherit;
  font-size: 1.1rem;
}

.form-control-recovery .error {
  margin-bottom: 1rem;
}

.form-control-recovery input {
  padding: 0.7rem;
}

.recovery-button {
  padding: 0.7rem;
  color: white;
  background: var(--blue-btn-color);
  text-transform: uppercase;
  font-size: 1.2rem;
}

.match-parent-dimensions {
  height: 100%;
  width: 100%;
}

.no-decoration-anchor {
  text-decoration: none;
  color: inherit;
}

.centered-grid {
  display: grid;
  place-content: center;
}

.replaced-element-container img {
  width: 100%;
  height: 20%;
  object-fit: contain;
  text-align: center;
}

.medium-svg svg {
  height: 3rem;
}

.small-svg svg {
  height: 1.2rem;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  background-color: black;
  text-align: center;
  font-weight: bold;
  padding: 0.2rem 1rem;
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}

.mobile {
  display: none;
}

@media (max-width: 40rem) {
  .logo {
    gap: 0.2rem;
    margin-left: 45px;
    grid-template-columns: 3rem 200px;
    place-content: center;
  }
  .logo :nth-child(2) {
    font-size: 1.1rem;
    font-weight: bold;
  }
  main nav ul {
    display: grid;
    margin-right: 0;
  }
  main nav ul > * {
    text-align: left;
  }
  .mobile-links {
    background: white;
    min-height: 200px;
    z-index: calc(var(--backdrop-z-index) + 100);
  }
  main nav ul > * {
    margin-left: 0;
    padding: 0.9rem;
    padding-bottom: 0;
    padding-left: 0.6rem;
  }
  .hero-section {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
  .hero-text,
  .features {
    padding: 0.8rem;
    padding-top: 0;
    grid-auto-rows: 400px;
  }
  .features {
    display: flex;
    flex-direction: column;
  }
  .features p {
    padding: 0;
    margin: 0;
  }
  .features .replaced-element-container {
    max-height: 400px;
  }
  .features .replaced-element-container img {
    height: 100%;
    width: 100%;
  }
  .features :nth-child(2) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .features :nth-child(4) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .features :nth-child(6) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .features :nth-child(8) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .features :nth-child(10) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .features :nth-child(12) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .features :nth-child(14) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .features :nth-child(16) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .desktop {
    display: none;
  }
  header {
    display: block;
  }
  .mobile {
    display: grid;
    place-content: center;
    position: absolute;
    top: 1rem;
  }
  .mobile-link-show {
    width: 40px;
  }
  .mobile-line {
    width: 100%;
    border: 3px solid black;
    margin-bottom: 0.2rem;
    margin-left: 0.1rem;
  }
  .plan-grid {
    flex-direction: column;
  }
}

