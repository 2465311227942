.match-parent-dimensions {
    height: 100%;
    width: 100%;
}

.no-decoration-anchor {
    text-decoration: none;
    color: inherit;
}

.centered-grid {
    display: grid;
    place-content: center;
}

.replaced-element-container img {
    width: 100%;
    height: 20%;
    object-fit: contain;
    text-align: center;
}

.medium-svg svg {
    height: 3rem;
}

.small-svg svg {
    height: 1.2rem;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    background-color: black;
    text-align: center;
    font-weight: bold;
    padding: .2rem 1rem;
    cursor: pointer;
}

.text-align-center {
    text-align: center;
}