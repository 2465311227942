.registeration-form {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: var(--box-shadow-common);
    background: white;
    z-index: var(--registration-z-index); 
    min-height: 30rem;

    h1 {
        margin-top: 0;
        text-align: center;
        text-decoration: underline;
    }
}

.form-control {
    display: grid;
    grid-template-columns: 100px 1fr;
    font-size: 1.2rem;
    padding: .5rem;
}

.form-control > * {
    align-self: center;
}

.form-control input {
    font-family: inherit;
    font-size: inherit;
    padding: .6rem;
}

.plan-explainer {
    position: relative;
    border: 2px solid black;
    padding: .5rem;
    min-height: 24rem;
    border-radius: 10px;
    box-shadow: var(--box-shadow-common);
}

.plan-explainer-features {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: .5rem;
    font-size: .9rem;
    margin-bottom: 1rem;
}

.plan-explainer-features > * {
    align-self: center;
}

.plan-explainer-price {
    margin: 0;
    padding: 0;
    margin-bottom: .5rem;
}

.plans-pricing {
    text-align: center;
}

.plan-explainer-text { 
    padding-bottom: .3rem;
}

.plan-explainer-buy-btn,
.registration-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--blue-btn-color);
    color: white;
    font-family: inherit;
    font-size: 1rem;
    padding: .4rem;
    border-radius: 5px;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
}

.pricing-centered {
    min-height: 80vh;
}

.plan-grid {
    display: flex;
    gap: 1rem;
}

.form-notification {
    --color: red;
    display: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--color);
}

.form-notification-valid {
    --color: green;
}

.account-creation-success p,
.account-creation-failure p {
    font-size: 1.2rem;
}

.form-control-recovery {
    margin: 0 auto;
    width: 50%;
    margin-top: 3rem;
    display: grid;
    gap: 0.5rem;
}

.form-control-recovery * {
    display: inline-block;
    font-family: inherit;
    font-size: 1.1rem;
}

.form-control-recovery .error {
    margin-bottom: 1rem;
}

.form-control-recovery input {
    padding: .7rem;
}
.recovery-button {
    padding: .7rem;
    color: white;
    background: var(--blue-btn-color);
    text-transform: uppercase;
    font-size: 1.2rem;
}