.mobile {
    display: none;
}

@media (max-width: 40rem) {
    .logo {
        gap: 0.2rem;
        margin-left: 45px;
        grid-template-columns: 3rem 200px;
        place-content: center;
    }

    .logo :nth-child(2) {
        font-size: 1.1rem;
        font-weight: bold;
    }

    main nav ul {
        display: grid;
        margin-right: 0;
    }

    main nav ul > * {
        text-align: left;
    }

    .mobile-links {
        background: white;
        min-height: 200px;
        z-index: calc(var(--backdrop-z-index) + 100);
    }

    main nav ul > * {
        margin-left: 0;
        padding: 0.9rem;
        padding-bottom: 0;
        padding-left: 0.6rem;
    }

    .hero-section {
        grid-template-columns: 1fr;
        margin-top: 0;
    }

    .hero-text, 
    .features {
        padding: 0.8rem;
        padding-top: 0;
        grid-auto-rows: 400px;
    }

    .features {
        display: flex;
        flex-direction: column;
    }

    .features p {
        padding: 0;
        margin: 0;
    }

    .features .replaced-element-container {
        max-height: 400px;
    }

    .features .replaced-element-container img {
        height: 100%;
        width: 100%;
    }

    $children: 1, 3, 5, 7, 9, 11, 13, 15;

    @each $i in $children {
        .features :nth-child(#{$i + 1}) {
            margin-top: 0;
            margin-bottom: 3rem;
        }
    }

    .desktop { 
        display: none;
    }

    header {
        display: block;
    }

    .mobile {
        display: grid;
        place-content: center;
        position: absolute;
        top: 1rem;
    }

    .mobile-link-show {
        width: 40px;
    }

    .mobile-line {
        width: 100%;
        border: 3px solid black;
        margin-bottom: .2rem;
        margin-left: .1rem;
    }

    .plan-grid {
        flex-direction: column;
    }
}